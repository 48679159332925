import React from "react"
// import { useStaticQuery, graphql } from "gatsby"
// import ImageCard from "./basic-components/image-card"
// import Imag from "../assets/img/pexels-julia-volk-6297518.jpg"

const TwoColumnsFullwidth = () => {

    return(
        <div className="two-columns-fullwidth-section-wrapper">
            <div className="two-columns-fullwidth-section uk-child-width-1-2@s" data-uk-grid>
                <div className="left-col uk-text-right@s">
                    <div className="column-header">Personal Injury Compensation?</div>
                    <p>Been involved in a Road Traffic Accident in the UK? if so, we are here to help. Along with offering services such as, vehicle repairs and car hire, we can also assist you with making a personal injury claim.</p>
                </div>
                <div className="right-col uk-text-left@s">
                    <div className="column-header">Eligibility</div>
                    <p>We will assess your case before appointing any solicitor to ensure that the best possible firm is chosen for you, therefore none of your time is taken up unnecessarily. </p>
                </div>
                
            </div>
        </div>
    )
    
}

export default TwoColumnsFullwidth
import React from "react"
// import { useStaticQuery, graphql } from "gatsby"
// import ImageCard from "./basic-components/image-card"
// import Imag from "../assets/img/pexels-julia-volk-6297518.jpg"

const TwoColumnDescription = () => {

    return(
        <div className="two-column-description-section-wrapper">
            <div className="uk-text-center">
                <div className="section-header">Why SEO Developments?</div>
                <p>SEO Developments is a claims management company regulated by Financial conduct Authority and is paid a recommendation fee from its Solicitors .Whilst we do not charge you a fee, the solicitor we recommend will charge you a fee. You do not need to use us to make a claim for compensation, you can either go direct to your own solicitor or make a claim yourself.</p>
            </div>


            <div className="two-column-description-section uk-child-width-1-2@s" data-uk-grid>
                <div className="left-col uk-text-center">
                    <div className="column-header">Confidence</div>
                    <p>Been involved in a Road Traffic Accident in the UK? if so, we are here to help. Along with offering services such as, vehicle repairs and car hire, we can also assist you with making a personal injury claim.</p>
                </div>
                <div className="right-col uk-text-center">
                    <div className="column-header">World Class Service</div>
                    <p>We will assess your case before appointing any solicitor to ensure that the best possible firm is chosen for you, therefore none of your time is taken up unnecessarily. </p>
                </div>
                
            </div>
            <div className="two-column-description-section uk-child-width-1-2@s" data-uk-grid>
                <div className="left-col uk-text-center">
                    <div className="column-header">Privacy &amp; Security</div>
                    <p>Been involved in a Road Traffic Accident in the UK? if so, we are here to help. Along with offering services such as, vehicle repairs and car hire, we can also assist you with making a personal injury claim.</p>
                </div>
                <div className="right-col uk-text-center">
                    <div className="column-header">Great Support</div>
                    <p>We will assess your case before appointing any solicitor to ensure that the best possible firm is chosen for you, therefore none of your time is taken up unnecessarily. </p>
                </div>
                
            </div>
        </div>
    )
    
}

export default TwoColumnDescription
import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"

import headerBgImage from "../assets/img/homepage-page-1.jpg"
// import Signup from "../components/signup"
// import Carousel from "../components/carousel"
// import Hero from "../components/basic-components/hero"
import TwoColumnsFullwidth from "../components/two-column-fullwidth"
import TwoColumnDescription from "../components/two-column-description"
import CTA from "../components/cta"

//variables to be used and passed to the layout which in turn will be passed to the header component
var headerTitle = "We are a claim management company regulated by FCA";
var headerDescription = "";
var headerCTAPrimaryLabel = "No win no fee";
var headerCTAPrimaryUrl = "/no-win-no-fee";
var headerCTASecondaryLabel = "";
var headerCTASecondaryUrl = "#0";

// var carouselTitle = "Memorials for all";
// var carouselContents = {carouselTitle};


var pageData={
  headerTitle, 
  headerDescription, 
  headerCTAPrimaryLabel, 
  headerCTAPrimaryUrl, 
  headerCTASecondaryLabel, 
  headerCTASecondaryUrl,
  headerBgImage,
};


const IndexPage = () => (

  <div>
    <Layout pageData={pageData}>
      <SEO title="Home" />

      <TwoColumnsFullwidth></TwoColumnsFullwidth>

      <TwoColumnDescription></TwoColumnDescription>

      <CTA 
        title="Start your claim here"
        subtitle="If you are ready to enquire about making a claim, please contact us straight away using the following method:"
        btnLabel="Call Us Now"
        btnUrl="tel:03301343283"
        

      ></CTA>

    </Layout>


    
  </div>
)

export default IndexPage

import React from "react"
import Button from "./basic-components/button"

// import { useStaticQuery, graphql } from "gatsby"
// import ImageCard from "./basic-components/image-card"
// import Imag from "../assets/img/pexels-julia-volk-6297518.jpg"

const CTA = ({btnLabel, btnUrl, title, subtitle}) => {

    return(
        <div className="cta-section-wrapper">
            <div className="cta-section uk-text-center">
                <div className="section-header">{title}</div>
                <p>{subtitle}</p>
                <a href={btnUrl}>
                    
                    <Button label={btnLabel} use="cta"></Button>
                </a>
            </div>
        </div>
    )
    
}

export default CTA